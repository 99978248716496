<template>
  <div class="lzlinks-opencamera">
    <div class="lzlinks-opencamera-btn">
      <!-- <el-button
      style="position: fixed; top: 20px; right: 40px; z-index: 100"
      @click="cut"
      type="info"
      >抓拍</el-button
    > -->
    <el-button
      style="position: fixed; top: 20px; right: 120px; z-index: 100"
      @click="open"
      type="success"
      >{{btnName}}</el-button
    >
    </div>
    
    
    <div id="myPlayer"></div>
  </div>
</template>
<script>
// import ezuikit from "../../public/ezuikit.js";
import  EZUIKit from 'ezuikit-js';
let _self;

export default {
  name: "openCamera",
  data() {
    return {
      videoUrl: "",
      player: null,
      accessToken: "",
      type:'rec',
      btnName:"查看回放"
    };
  },
  methods: {
    stopCamera() {
      if (_self.player != null) {
        _self.player.stop();
        _self.player = null;
      }
    },
    open() {
       _self.player.stop()
  .then(()=>{
   _self.player.changePlayUrl({type:this.type,
      accessToken:_self.accessToken,
      deviceSerial:_self.$route.query.deviceSerial,
      channelNo:_self.$route.query.channelNo,
      hd:false,
      validCode:_self.$route.query.validCode,
      });
      if (this.type=='rec') {
        this.type='live';
        this.btnName="实时画面"
      }else{
        this.type='rec';
        this.btnName="查看回放"
      }

  });
      // _self.player.play();
      
    },
    cut() {
      _self.$api.camera
        .cutPic(_self.$route.query.ysId)
        .then((response) => {
          if (response.code == 200) {
            if (response.data.indexOf("http") >= 0) {
              window.open(response.data);
            } else {
              _self.$message({
                type: "warning",
                message: response.data,
              });
            }
          } else {
            _self.$message({
              type: "warning",
              message: response.msg,
            });
          }
        })
        .catch((err) => {
          _self.$message({
            type: "error",
            message: err.message,
          });
        });
    },
  },
  beforeCreate() {
    _self = this;
  },
  mounted() {
    //  登录获取权限
    _self.$api.camera
      .getToken(_self.$route.query.ysId)
      .then((response) => {
        if (response.code == 200 && response.data) {
          _self.accessToken = response.data;
          // setTimeout(function () {
          _self.videoUrl = "ezopen://" + _self.$route.query.validateCode + "@open.ys7.com/" +
            _self.$route.query.deviceSerial + "/" +_self.$route.query.channelNo +".live";
            // console.log(_self.videoUrl);
            
            _self.player = new EZUIKit.EZUIKitPlayer({
              id: "myPlayer",
              url:
                "ezopen://" +
                _self.$route.query.validateCode +
                "@open.ys7.com/" +
                _self.$route.query.deviceSerial +
                "/" +
                _self.$route.query.channelNo +
                ".live", // ezopen播放地址逗号分隔
              accessToken: response.data,
              decoderPath: "",
              width: document.body.clientWidth, // 宽度高度为容器规格，将按照窗口数均分
              height: document.body.clientHeight,
              splitBasis: 1, //设置窗口切割参数
              template:"theme",
              footer:['hd'],
              handleError: (e) => {},
            });
            // _self.player = new ezuikit.EZUIPlayer({
            //   id: "myPlayer",
            //   url:
            //     "ezopen://" +
            //     _self.$route.query.validateCode +
            //     "@open.ys7.com/" +
            //     _self.$route.query.deviceSerial +
            //     "/" +
            //     _self.$route.query.channelNo +
            //     ".live", // ezopen播放地址逗号分隔
            //   accessToken: response.data,
            //   decoderPath: "",
            //   width: document.body.clientWidth, // 宽度高度为容器规格，将按照窗口数均分
            //   height: document.body.clientHeight,
            //   splitBasis: 1, //设置窗口切割参数
            //   handleError: (e) => {},
            // });
          // }, 0);
        } else {
          _self.$message({
            type: "warning",
            message: response.msg,
          });
        }
      })
      .catch((err) => {
        _self.$message({
          type: "error",
          message: err.message,
        });
      });
  },
  beforeDestroy() {
    // 销毁前停止
    _self.stopCamera();
  },
};
</script>
<style scoped >
.lzlinks-opencamera {
  padding: 10px;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;

}

</style>